.backtotopmain{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    background: linear-gradient(to left,#184A8E,#000135);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
}

.backtotoptext{
    font-size: 15px;
    margin-top: 120px;
    margin-bottom: 30px;
    padding: 0px 20px;
    text-align: center;
}

.backtotopcircles{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    gap: 10px;
}

.backtotopcircles div{
    width: 20px;
    height: 20px;
    background: white;
    border-radius: 20px;
}

.backtotopbutton{
    position: relative;
    width: 80%;
    background: #113372;
    text-align: center;
    padding: 20px 10px;
    font-size: 18px;
    top: 30px;
    border-radius: 10px;
    cursor: pointer;
}