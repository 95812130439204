.dresscode{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    min-height: 100vh;
    width: 100%;
    overflow: hidden;
}

.dresscodeunder{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 100px;
    margin: 50px 0px;
}

.dresscodecontent{
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.dresscodecontent h1{
    font-size: 25px;
    font-weight: 600;
    color: #000135;
}

.dresscodecontent .heading{
    text-align: center;
    font-size: 35px;
    font-weight: 700;
}

.dresscodecontent p{
    line-height: 25px;
    color: #55565b;
    text-align: justify;
}

.dresscodefinancial{
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: 30px;
}

.dresscodefinancial h1{
    font-size: 25px;
    font-weight: 600;
    color: #000135;
}

.dresscodefinancial .financialunder{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
    width: 100%;
}

.financialunder div{
    width: 30%;
    display: flex;
    justify-content: flex-start;
    line-height: 30px;
}

.financialunder div ul{
    padding: 0;
    margin: 0;
    list-style: circle;
}

.financialunder div ul li{
    padding: 0;
    margin: 0;
    color: #55565b;
}

.dresscodeweaving{
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: 30px;
}

.dresscodeweaving h1{
    font-size: 25px;
    font-weight: 600;
    color: #000135;
}

.dresscodeweaving .weavingunder{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
    width: 100%;
}

.weavingunder div{
    width: 30%;
    display: flex;
    justify-content: flex-start;
    line-height: 30px;
}

.weavingunder div ul{
    padding: 0;
    margin: 0;
    list-style: circle;
}

.weavingunder div ul li{
    padding: 0;
    margin: 0;
    color: #55565b;
}

.morelink{
    margin-top: 5%;
}

.morelink a{
    text-decoration: none;
    font-size: 20px;
    font-weight: 600;
    color: black;
}

.productillustate2{
    width: 100%;
    height: auto;
    margin-top: 5%;
    margin-bottom: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.productillustate2 div{
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.productillustate2 div img{
    width: 80%;
}

@media screen and (max-width: 950px){
    .dresscodeweaving .weavingunder, .dresscodefinancial .financialunder{
        justify-content: flex-start;
    }
    .weavingunder div, .financialunder div{
        width: 50%;
        padding-bottom: 30px;
    }
}

@media screen and (max-width: 650px){
    .dresscodeweaving .weavingunder, .dresscodefinancial .financialunder{
        justify-content: flex-start;
    }
    .weavingunder div, .financialunder div{
        width: 100%;
        padding-bottom: 30px;
    }
}

@media screen and (max-width: 450px){
    .dresscodeunder{
        padding: 0 50px;
        margin: 20px 0px;
    }

    .dresscodecontent .heading{
        text-align: center;
        font-size: 35px;
        font-weight: 700;
    }

    .dresscodecontent h1{
        font-size: 20px;
        font-weight: 600;
        color: #000135;
    }

    .dresscodeweaving{
        margin-bottom: 0;
    }

    .dresscodeweaving h1 {
        font-size: 20px;
        font-weight: 600;
        color: #000135;
    }

    .dresscodefinancial h1 {
        font-size: 20px;
        font-weight: 600;
        color: #000135;
    }

    .productillustate2 div{
        width: 100%;
    }

    .productillustate2 div img{
        width: 100%;
    }
}