.aboutusDiv{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background: white;
    min-height: 100vh;
    font-family: 'Montserrat', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0px 100px;
    text-align: center;
    overflow-x: hidden;
    overflow: hidden;
    z-index: 4;
}

.aboutusUpperBox h1{
    font-size: 50px;
    font-weight: 600;
    text-transform: uppercase;
    color: #000135;
}

.aboutusMiddleBox p{
    font-weight: 300;
    font-size: 25px;
    color: #1D1D1D;
}

.aboutusLowerBox{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row-reverse;
    margin-top: 30px;
    margin-bottom: 30px;
}

.firstmission{
    position: absolute;
    width: 17%;
    background: #113372;
    border-radius: 10px;
    transition: all 0.5s;
    z-index: 1;
    cursor: pointer;
}

.firstmission p{
    color: white;
    font-size: 25px;
    font-weight: 500;
    margin: 15px 0px;
    z-index: 10;
}

.textmission{
    width: 70%;
    border: 1px gray solid;
    opacity: 0;
    transition: all 0.2s;
    z-index: 0;
}

.onHoverBox{
    padding: 0px 60px;
    color: '#000135';
    font-size: 15px;
    text-align: left;
}

.textmission.active{
    transition: all 0.5s;
    transition-delay: 0.3s;
    opacity: 1;
    transform: translateX(15%) !important;
}

.textmission.active ~ .firstmission{
    /* position: relative; */
    transform: translateX(-200%) !important;
    /* left: 140px; */
}

@media screen and (max-width: 650px){
    .aboutusUpperBox h1{
        margin-top: 50px;
        font-size: 40px;
        font-weight: 600;
    }

    .aboutusMiddleBox p{
        font-weight: 300;
        font-size: 20px;
        color: #1D1D1D;
    }

    .aboutusLowerBox{
        margin-top: 10px;
        margin-bottom: 10px;
        flex-direction: column-reverse;
    }

    .firstmission{
        position: relative;
        width: 50%;
    }

    .textmission{
        width: 100%;
    }

    .onHoverBox{
        padding: 0px 40px;
        font-size: 12px;
    }

    .textmission.active{
        transform: translateY(50%) !important;
        margin-bottom: 100px;
    }
    
    .textmission.active ~ .firstmission{

        transform: translateX(0%) !important;
    }
}

@media screen and (max-width: 420px){
    .aboutusDiv{
        padding: 0px 50px;
    }

    .aboutusUpperBox h1{
        margin-top: 50px;
        font-size: 25px;
        font-weight: 600;
    }

    .aboutusMiddleBox p{
        font-weight: 300;
        font-size: 15px;
        color: #1D1D1D;
    }

    .aboutusLowerBox{
        margin-top: 10px;
        margin-bottom: 10px;
        flex-direction: column-reverse;
    }

    .firstmission{
        position: relative;
        width: 50%;
    }

    .firstmission p{
        font-size: 15px;
        font-weight: 500;
        margin: 10px 0px;
    }

    .textmission{
        width: 100%;
    }

    .onHoverBox{
        padding: 0px 20px;
        font-size: 10px;
    }

    .textmission.active{
        transform: translateY(50%) !important;
        margin-bottom: 80px;
    }
    
    .textmission.active ~ .firstmission{

        transform: translateX(0%) !important;
    }
}