.sponsors{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* min-height: 100vh; */
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    overflow: hidden;
}

.sponsorshead h1{
    font-size: 40px;
    font-weight: 600;
    text-transform: uppercase;
    color: #000135;
    padding: 40px 0px;
}

.sponsorimage img{
    width: 100%;
    height: auto;
}

@media screen and (max-width: 450px){
    .sponsorshead h1{
        font-size: 30px;
    }
}