.autopilot{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    min-height: 100vh;
    width: 100%;
    overflow: hidden;
}

.autopilotunder{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 100px;
    margin: 50px 0px;
}

.autopilotcontent{
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.autopilotcontent h1{
    font-size: 25px;
    font-weight: 600;
    color: #000135;
}

.autopilotcontent .heading{
    text-align: center;
    font-size: 35px;
    font-weight: 700;
}

.autopilotcontent p{
    line-height: 25px;
    color: #55565b;
    text-align: justify;
}

.autopilotspare{
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: 30px;
}

.autopilotspare h1{
    font-size: 25px;
    font-weight: 600;
    color: #000135;
}

.autopilotspare .spareunder{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
}

.spareunder div{
    width: 45%;
    display: flex;
    justify-content: flex-start;
    line-height: 30px;
}

.spareunder div ul{
    padding: 0;
    margin: 0;
    list-style: circle;
}

.spareunder div ul li{
    padding: 0;
    margin: 0;
    color: #55565b;
}

.morelink a{
    text-decoration: none;
    font-size: 20px;
    font-weight: 600;
    color: black;
}

.productillustate2{
    width: 100%;
    height: auto;
    margin-top: 5%;
    margin-bottom: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.productillustate2 div{
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.productillustate2 div img{
    width: 80%;
}

@media screen and (max-width: 950px){
    .autopilotspare .spareunder{
        justify-content: flex-start;
    }
    .spareunder div{
        width: 50%;
        padding-bottom: 30px;
    }
}

@media screen and (max-width: 650px){
    .autopilotspare .spareunder{
        justify-content: flex-start;
    }
    .spareunder div{
        width: 100%;
        padding-bottom: 30px;
    }
}

@media screen and (max-width: 450px){
    .autopilotunder{
        padding: 0 50px;
        margin: 20px 0px;
    }

    .autopilotcontent .heading{
        text-align: center;
        font-size: 35px;
        font-weight: 700;
    }

    .autopilotcontent h1{
        font-size: 20px;
        font-weight: 600;
        color: #000135;
    }

    .autopilotspare{
        margin-bottom: 0;
    }

    .autopilotspare h1 {
        font-size: 20px;
        font-weight: 600;
        color: #000135;
    }

    /* .productillustate1 div{
        width: 100%;
    }

    .productillustate1 div video{
        width: 100%;
        clip-path: inset(2% 2% 2% 2%)
    } */

    .productillustate2 div{
        width: 100%;
    }

    .productillustate2 div img{
        width: 100%;
    }
}